<template>
    <div id="add-admin" class="container position-absolute top-50 start-50 translate-middle">
        <div class="row mb-4">
            <div class="col"></div>
            <div class="col-5 border-bottom border-4 border-primary pb-4">
                <h2 class="text-primary"><b>新增管理員</b></h2>
            </div>
            <div class="col"></div>
        </div>
        <div class="row pt-4">
            <form>
                <div class="row mb-4 ms-5">
                    <label class="col-4 pe-4 col-form-label fs-4 text-end" for="inputName">姓名</label>
                    <div class="col ps-4">
                        <input type="text" class="w-50 form-control form-control-lg" id="inputName" v-model="name" required>
                    </div>
                </div>
                <div class="row mb-4 ms-5">
                    <label class="col-4 pe-4 col-form-label fs-4 text-end" for="inputAccount">帳號</label>
                    <div class="col ps-4">
                        <input type="text" class="w-50 form-control form-control-lg" id="inputAccount" v-model="account" required>
                    </div>
                </div>
                <div class="row mb-4 ms-5">
                    <label class="col-4 pe-4 col-form-label fs-4 text-end" for="inputPassword">密碼</label>
                    <div class="col ps-4">
                        <input type="password" class="w-50 form-control form-control-lg" id="inputPassword" v-model="password" required>
                    </div>
                </div>
                <button type="button" class="mt-4 px-4 btn btn-primary btn-lg rounded-pill" @click="add">新增</button>
            </form>
        </div>
    </div>
    <div id="liveSuccessToast" class="toast align-items-center position-fixed top-50 start-50 translate-middle-x" data-bs-delay="1500" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
            <div class="toast-body">
                新增成功，將跳轉至登入頁面
            </div>
            <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
    </div>
    <div id="liveFailToast" class="toast align-items-center position-fixed top-50 start-50 translate-middle-x" data-bs-delay="1500" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
            <div class="toast-body">
                新增失敗！
            </div>
            <button type="button" class="btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
    </div>
</template>

<script setup>
import { serialize, deserialize } from "../js/msgpack.js";
import { Toast } from 'bootstrap'
import { ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()

const account = ref("")
const password = ref("")
const name = ref("")

const add = () => {
    if (!account.value || !password.value || !name.value) {
        alert('請填寫姓名、帳號和密碼');
        return;
    }

    var postData = {
        account: account.value,
        password: password.value,
        name: name.value
    };
    var bytes = serialize(postData);
    account.value = "";
    password.value = "";
    name.value = "";

    fetch("https://skpb.wmt35.idv.tw/add_admin", {
        method: "POST",
        body: bytes,
        headers: {
            "Content-Type": "application/x-msgpack",
            Accept: "application/x-msgpack"
        }
    })
    .then((res) => (res.arrayBuffer()))
    .then((buf) => {
        let data = deserialize(new Uint8Array(buf));
        console.log(data);
        if (data.state === 1) {
            let liveSuccessToast = document.getElementById("liveSuccessToast");
            let successToast = new Toast(liveSuccessToast);
            successToast.show();
            setTimeout(() => {
                router.push('/Login');
            }, 3000);
        } else {
            console.log(`Buf Error`);
            let liveFailToast = document.getElementById("liveFailToast");
            let failToast = new Toast(liveFailToast);
            failToast.show();
        }
    })
    .catch((error) => {
        console.log(`Catch Error: ${error}`);
    });
}
</script>